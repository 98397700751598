$(document).ready(function() {
    // slider
    var slider = $(".slider:not(.slidecount-1)")
        .slick({ autoplay: true, arrows: false, fade: true, autoplaySpeed: 7000, speed: 2000 })
        .on("beforeChange", function(
            event,
            slick,
            currentSlide,
            nextSlide
        ) {
            //console.log('test');
            set_slider_nav_active(nextSlide);
        });

    $(document).on("click", ".slide-nav-thumbnail", function(e) {
        e.preventDefault();
        $("[data-slide-target]").removeClass("active");
        //alert('test');
        var target = $(this).data("slide-target");
        //$('.slider:not(.slidecount-1)').slickGoTo(target);
        //$('.slider:not(.slidecount-1)').slick('slickGoTo', target, true);
        slider.slick("slickGoTo", target);
    });

    $(".navbar li.menu-item-has-children a.dropdown-toggle").click(function(e) {
        //alert($(this).attr('href'));
        //window.location.replace($(this).attr("href"));
    });

    $(".dropdown-toggle-sub").on("click", function(e) {
        e.stopPropagation();
        e.preventDefault();
    });

    $(".lightbox").colorbox({
        rel: "lightbox",
        width: "90%",
        height: "90%",
        previous: "Vorige",
        next: "Volgende",
        close: "Sluiten",
        xhrError: "Fout bij het laden van inhoud",
        imgError: "De afbeelding kan niet getoond worden"
    });

    // check paragraphs in rte-two-col for image wrappers and display over all columns
    console.log('two col check');
    $(".rte-two-columns > p").each(function(){
        var children = $(this)
            .children(".size-large")
            .parent()
            .addClass("column-span");
        console.log(children);
    });
});

function set_slider_nav_active(slide) {
    $("[data-slide-target]").removeClass("active");
    $("[data-slide-target='"+slide+"']").addClass('active');
}
